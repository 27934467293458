.featureCard {
  padding: 52px 42px;
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media (max-width: 1200px) {
  .featureCard {
    padding: 30px 20px;
  }
}

@media (max-width: 1024px) {
  .featureCard {
    padding: 0;
    padding-right: 10px;
  }
}

.featureCard__icon {
  margin-bottom: 36px;
}

@media (max-width: 1024px) and (min-width: 769px) {
  .featureCard__icon {
    height: 40px;
    margin-bottom: 24px;
  }
}

.featureCard__title {
  margin-bottom: 24px;
}

@media (max-width: 1024px) {
  .featureCard__title {
    font-size: var(--sFontBody) !important;
    margin-bottom: 16px;
  }
}

@media (max-width: 768px) {
  .featureCard__title > * {
    font-size: calc(30rem / 16) !important;
  }
}

.featureCard__content {
  font-size: var(--sFontBig);
  font-weight: 300;
}

.featureCard__content p {
  font-weight: 300;
}

@media (max-width: 1024px) {
  .featureCard__content {
    font-size: var(--sFontExtraTiny);
  }
}

@media (max-width: 768px) {
  .featureCard__content {
    font-size: var(--sFontBody);
  }
}
