.langDropdown {
  position: relative;
  display: inline-flex;
  font-size: var(--sFontSmall);
}

.langDropdown__triggerLabelWrapper {
  display: inline-grid;
  grid-template-areas: "label";
}

.langDropdown__trigger {
  height: 45px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background: none;
  border-radius: calc(45px / 2);
  color: var(--cText);
  border: none;
  appearance: none;
  padding: 0 15px;
  font-family: var(--fontAccent);
  font-size: inherit;
  line-height: 1;
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  outline: none;
  margin: 0;
}

.langDropdown__trigger:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

.langDropdown__trigger:hover {
  background: var(--cShadeLight);
}

@media (max-width: 1024px) {
  .langDropdown__trigger {
    height: 45px;
    border-radius: 12px;
    padding: 0 10px;
  }
}

.langDropdown__trigger svg {
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  flex-shrink: 0;
}

.langDropdown_open .langDropdown__trigger svg:last-child {
  transform: scaleY(-1);
}

.langDropdown__triggerLabel {
  position: relative;
  grid-area: label;
  text-align: left;
}

.langDropdown__triggerLabelWrapper {
  margin-right: 10px;
}

.langDropdown__triggerLabel_hidden {
  visibility: hidden;
}

.langDropdown__dropdown {
  position: absolute;
  top: 100%;
  width: 100%;
  display: none;
  right: 0;
  background: var(--cBackgroundInverse);
  border-radius: 18px;
  z-index: 1;
}

.langDropdown_open .langDropdown__dropdown {
  display: flex;
  flex-direction: column;
}

.langDropdown__dropdownButton {
  font: inherit;
  text-align: left;
  padding: 6px 15px;
  min-height: 42px;
  background: none;
  border: none;
  color: var(--cTextInverse);
  position: relative;
  cursor: pointer;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  display: flex;
  align-items: center;
  outline: none;
  margin: 0;
}

.langDropdown__dropdownButton > *:not(:last-child) {
  margin-bottom: 10px;
}

.langDropdown__dropdownButton:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

.langDropdown__dropdownButton svg {
  flex-shrink: 0;
}

@media (max-width: 1024px) {
  .langDropdown__dropdownButton {
    padding: 6px 10px;
  }
}

.langDropdown__dropdownButton:hover {
  background-color: rgba(255, 255, 255, 0.05);
}
