.input {
}

.input__field {
  border: 1px solid var(--cBorderLight);
  padding: 40px 50px;
  border-radius: 30px;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--fontAccent);
  font-size: var(--sFontH4);
  outline: none;
}

@media (max-width: 1024px) {
  .input__field {
    padding: 20px 30px;
    font-size: var(--sFontBody);
    border-radius: 18px;
  }
}

.input__field:global(.focus-visible) {
  box-shadow: var(--focusRingSecondary);
}

.input__field::placeholder {
  color: currentColor;
}
