.button {
  display: inline-flex;
  align-items: center;
  position: relative;
  justify-content: center;
  padding-inline: 28px;
  border-radius: 9999em;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  color: var(--cTextInverse);
  background: var(--cTheme);
  min-height: 49px;
  box-sizing: border-box;
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  overflow: hidden;
  outline: none;
  border: none;
  appearance: none;
  font: inherit;
  font-weight: 500;
  /*font-family: var(--fontBody);*/
  gap: 16px;
}

.button_iconOnly {
  padding-inline: 6px;
  min-width: 49px;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .button {
    padding-inline: 20px;
    min-height: 40px;
  }

  .button_iconOnly {
    padding-inline: 6px;
    min-width: 40px;
  }
}

.button__icon {
  margin-right: -6px;
  margin-block: -6px;
}

.button svg {
  display: block;
}

.button:before {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--gradientCommon);
  transition-duration: var(--tGeneric);
  transition-timing-function: var(--tfStandart);
  border-radius: 9999em;
}

.button:hover {
  background: var(--cTheme);
}

.button:active {
  background: var(--cThemeActive);
}

.button:hover:before,
.button:active:before {
  opacity: 0;
}

.button__label {
  position: relative;
}

.button:global(.focus-visible) {
  box-shadow: var(--focusRing);
}

/*ghost*/

.button_ghost {
  background: transparent;
  color: var(--cText);
}

.button_ghost:before {
  display: none;
}

.button_ghost:hover {
  background-color: var(--cShadeLight);
}

.button_ghost:active {
  background-color: transparent;
}

/*outline*/

.button_outline {
  background: transparent;
  border: 2px solid var(--cText);
  color: var(--cText);
}

.button_outline:before {
  display: none;
}

.button_outline:hover {
  background-color: var(--cShadeLight);
}

.button_outline:active {
  background-color: transparent;
}

/*outline*/

.button_outlineColor {
  background: transparent;
  border: 2px solid var(--cTheme);
  color: var(--cText);
}

.button_outlineColor:before {
  display: none;
}

.button_outlineColor:hover {
  background-color: var(--cShadeLight);
}

.button_outlineColor:active {
  background-color: transparent;
}

/*wireframe*/

.button_wireframe {
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--cTheme);
  color: var(--cText);
}

.button_wireframe:before {
  opacity: 0;
}

.button_wireframe:hover,
.button_wireframe:active {
  background: none;
  color: var(--cTextInverse);
}

.button_wireframe:hover:before,
.button_wireframe:active:before {
  opacity: 1;
}
