.terms__nav {
  display: flex;
  flex-direction: column;
  font-family: var(--fontAccent);
}

.terms__nav > *:not(:last-child) {
  margin-bottom: 15px;
}

.terms__navItem {
  /*transition-duration: var(--tGeneric);*/
  /*transition-timing-function: var(--tfStandart);*/
}

.terms__navItem_active {
  font-size: var(--sFontExtraBig);
  color: var(--cTheme);
}

.terms__navItem_active .terms__navLink {
  color: var(--cTheme);
}

.terms__navLink {
  text-decoration: none;
  display: block;
  padding: 6px 0;
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
}

.terms__navLink:hover {
  /*text-decoration: underline;*/
  color: var(--cTheme);
}

@media (max-width: 768px) {
  .terms__nav {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: row;
    overflow: auto;
    counter-reset: counter;
    margin-inline: -20px;
    scroll-snap-type: x;
  }

  .terms__navItem,
  .terms__navItem_active {
    margin-bottom: 0 !important;
    white-space: nowrap;
    font-weight: 400;
    font-size: var(--sFontSmall);
    counter-increment: counter;
    display: block;
    scroll-snap-align: start;
  }

  .terms__navLink {
    padding: 11px 20px;
    background-color: #ecedf2;
    border-radius: 999em;
  }

  .terms__navItem:not(:last-child) {
    margin-right: -10px;
  }

  .terms__navItem {
    padding-left: 20px;
  }

  .terms__navItem:last-child {
    padding-right: 20px;
  }

  .terms__navLink::before {
    content: counter(counter) ". ";
  }
}
