:root {
  font-family: "Alfabet", sans-serif;
  --fontBody: "Alfabet", sans-serif;
  --fontAccent: "Inter", sans-serif;

  --cSuccess: #5fd255;
  --cError: #e85629;
  --cSuccessLight: #f1fae4;
  --cTheme: #5244f3;
  --cThemeActive: #473bd0;
  --cBackground: #fff;
  --cBackgroundInverse: #111214;
  --cShadeLight: #f3f3f3;
  --cShade: #c4c4c4;
  --cShadeActive: #bebebe;
  --cBorder: #654dfa;
  --cBorderLight: #C0C1C6;
  --cBorderInverse: rgba(193, 199, 204, 0.3);
  --cTextLight: #757575;
  --cText: #000;
  --cTextInverse: #fff;
  --cTextInverseLight: #bdbdbd;
  --cTextInverseLightSecondary: #828282;
  --focusRing: 0 0 0 3px var(--cTheme);
  --focusRingSecondary: 0 0 0 3px rgba(82, 68, 243, 0.5);

  --gradientCommon: linear-gradient(124.09deg, #b48ffc 16.04%, #4075fb 84.97%);

  --radius: 10px;
  --radiusSmall: 7px;

  --tGeneric: 150ms;
  --tFast: 100ms;
  --tfEaseOut: cubic-bezier(0.1, 1, 0.3, 1);
  --tfStandart: cubic-bezier(0.4, 0, 0.2, 1);

  --sFontH1: calc(86rem / 16);
  --sFontH2: calc(40rem / 16);
  --sFontH3: calc(30rem / 16);
  --sFontH4: calc(24rem / 16);

  --sFontH1M: calc(47rem / 16);
  /*--sFontH2: calc(40rem / 16);*/
  --sFontH3M: calc(17rem / 16);
  /*--sFontH4: calc(24rem / 16);*/

  --sFontH1S: calc(39rem / 16);
  /*--sFontH2: calc(40rem / 16);*/
  /*--sFontH3: calc(30rem / 16);*/
  /*--sFontH4: calc(24rem / 16);*/

  --sFontExtraTiny: calc(10rem / 16);
  --sFontTiny: calc(12rem / 16);
  --sFontSmall: calc(14rem / 16);
  --sFontBody: 1rem; /*16*/
  --sFontBig: calc(18rem / 16);
  --sFontExtraBig: calc(20rem / 16);
  /*--sFontExtraBig: 1.429rem; !*20*!*/
  /*--sFontSmall: 0.875rem; !*14*!*/

  /*--sSpasingTiny: 4px;*/
  /*--sSpasingSmall: 8px;*/
  /*--sSpasingMedium: 12px;*/
  /*--sSpasingLarge: 20px;*/
  /*--sSpasingLayout: 40px;*/

  --sContainerWidth: 1280px;

  --zAbove: 200;
  --zModal: 500;
}

@media (prefers-reduced-motion: reduce) {
  :root {
    --tGeneric: 0ms;
  }
}

.shadedBg > *,
.shaded > * {
  --cShade: var(--cMainBg);
  --cTextLight: #737373;
}

.standardBg > * {
  --cShade: #efefef;
  --cTextLight: #a5a5a5;
}

@media screen and (max-width: 1024px) {
  :root {
    --sFontH1: var(--sFontH1M);
    /*--sFontH2: var(--sFontH2M);*/
    --sFontH3: var(--sFontH3M);
  }
}

@media screen and (max-width: 768px) {
  :root {
    --sFontH1: var(--sFontH1S);
    /*--sFontH2: var(--sFontH2S);*/
    /*--sFontH3: var(--sFontH3S);*/
  }
}
