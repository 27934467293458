.pageHeader {
  padding-top: 80px;
  margin-bottom: 80px;
  position: relative;
}

.pageHeader_image {
  padding-bottom: 80px;
  --cText: var(--cTextInverse);
  color: var(--cText);
}

@media (max-width: 1024px) {
  .pageHeader {
    padding-top: 60px;
    margin-bottom: 60px;
  }

  .pageHeader_image {
    padding-bottom: 60px;
  }
}

@media (max-width: 768px) {
  .pageHeader {
    padding-top: 40px;
    margin-bottom: 40px;
  }

  .pageHeader_image {
    padding-bottom: 40px;
  }
}

.pageHeader > * {
  position: relative;
}

.pageHeader__imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.pageHeader__imageContainer::before {
  content: "";
  display: block;
  top: 0;
  left: 0;
  position: absolute;
  right: 80px;
  width: auto;
  height: 100%;
  background-color: var(--cBackgroundInverse);
  border-bottom-right-radius: 80px;
}

.pageHeader__image {
  height: 90%;
  top: 5%;
  position: absolute;
  right: -50px;
}

@media (max-width: 640px) {
  .pageHeader_image {
    padding-bottom: 270px;
  }

  .pageHeader__imageContainer::before {
    right: 20px;
    bottom: 100px;
    height: auto;
  }

  .pageHeader__image {
    top: auto;
    bottom: 0;
    height: 270px;
  }
}

.pageHeader_image .pageHeader__content {
  margin-right: 400px;
}

@media (max-width: 1024px) {
  .pageHeader_image .pageHeader__content {
    margin-right: 300px;
  }
}

@media (max-width: 768px) {
  .pageHeader_image .pageHeader__content {
    margin-right: 220px;
  }
}

@media (max-width: 640px) {
  .pageHeader_image .pageHeader__content {
    margin-right: 0;
  }
}

.pageHeader_image .pageHeader__title {
  font-weight: 300;
}

.pageHeader__subtitle {
  font-size: var(--sFontExtraBig);
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: var(--cTheme);
  padding-bottom: 20px;
  border-bottom: 1px solid var(--cTheme);
  margin-bottom: 40px;
}

@media (max-width: 1024px) {
  .pageHeader__subtitle {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .pageHeader__subtitle {
    margin-bottom: 20px;
  }
}

.pageHeader__text {
  max-width: 600px;
  margin-top: 30px;
}

@media (min-width: 1025px) {
  .pageHeader__text {
    font-size: var(--sFontH4);
  }
}

@media (max-width: 768px) {
  .pageHeader__text {
    margin-top: 20px;
  }
}
