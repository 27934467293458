.infoCard {
  padding: 52px 42px;
  border: 1px solid var(--cBorder);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .infoCard {
    padding: 30px 20px;
    border-radius: 20px;
  }
}

.infoCard__title {
  margin-bottom: 36px !important;
}

@media (max-width: 1024px) {
  .infoCard__title {
    font-size: calc(21rem / 16) !important;
    margin-bottom: 16px !important;
  }
}

@media (max-width: 768px) {
  .infoCard__title {
    font-size: calc(30rem / 16) !important;
  }
}

.infoCard__content {
  font-size: var(--sFontBig);
  margin-bottom: 70px;
  flex-grow: 1;
  font-weight: 300;
}

@media (max-width: 1024px) {
  .infoCard__content {
    font-size: var(--sFontExtraTiny);
    margin-bottom: 24px;
  }

  .infoCard__content + img {
    width: 36px;
  }
}

@media (max-width: 768px) {
  .infoCard__content {
    font-size: var(--sFontBody);
  }

  .infoCard__content + img {
    width: 60px;
  }
}
