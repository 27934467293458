.careerDetails {
  --blockSpacing: 40px;
  border-radius: 30px;
  border: 1px solid var(--cBorderLight);
}

@media (max-width: 1024px) {
  .careerDetails {
    --blockSpacing: 25px;
    border-radius: 20px;
  }
}

.careerDetails:not(:last-child) {
  margin-bottom: 30px;
}

.careerDetails__summary {
  padding: 30px var(--blockSpacing);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--sFontH4);
  font-weight: 500;
  font-family: var(--fontAccent);
  outline: none;
}

@media (max-width: 1024px) {
  .careerDetails__summary {
    padding-block: 20px;
  }
}

.careerDetails__summary::marker {
  display: none;
}

.careerDetails__summary {
  list-style: none;
}

.careerDetails__summary::-webkit-details-marker {
  display: none;
}

.careerDetails__summaryAction {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  background-color: var(--cTheme);
  transition-duration: var(--tFast);
  transition-timing-function: var(--tfStandart);
  position: relative;
  flex-shrink: 0;
  margin-left: 15px;
}

.careerDetails__summaryAction:hover {
  background-color: var(--cThemeActive);
}

.careerDetails__summaryAction:active {
  background-color: var(--cTheme);
}

.careerDetails__summaryAction::before,
.careerDetails__summaryAction::after {
  content: "";
  display: block;
  width: 24px;
  height: 2px;
  position: absolute;
  background-color: var(--cTextInverse);
  top: 50%;
  left: 50%;
  margin-top: -1px;
  margin-left: -12px;
}

.careerDetails__summaryAction::before {
  transform: rotate(90deg);
}

.careerDetails[open] .careerDetails__summaryAction {
  transform: rotate(45deg);
}

.careerDetails__summary:global(.focus-visible) .careerDetails__summaryAction {
  box-shadow: var(--focusRingSecondary);
}

.careerDetails__body {
  padding: 20px var(--blockSpacing) 55px;
}
