.priceTableRow {
  font-weight: 600;
  font-family: var(--fontAccent);
}

.priceTableRow td {
  padding-bottom: 30px;
  padding-top: 30px;
  border-top: 1px solid rgba(117, 126, 134, 0.5);
}

.priceTableRow__action {
  text-align: right;
}

.priceTableRow__image {
  width: 54px;
  height: 54px;
  display: block;
}

.priceTableRow__cell {
  font-family: var(--fontAccent);
}

.priceTableRow__cell_positive {
  color: var(--cSuccess);
  font-weight: 500;
}

.priceTableRow__cell_negative {
  color: var(--cError);
  font-weight: 500;
}

.priceTableRow__label {
  color: var(--cTextInverseLightSecondary);
  margin-right: 10px;
}

.center {
  text-align: center;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 auto;
  color: var(--cTheme);
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .priceTableRow__image {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .priceTableRow__button {
    min-height: 32px;
    padding-inline: 18px;
    font-size: var(--sFontSmall);
  }
}

@media screen and (min-width: 769px) {
  .priceTableRow__label {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .priceTableRow {
    display: grid;
    grid-template-columns: auto auto 1fr;
    grid-template-rows: repeat(3, auto);
    align-items: center;
  }

  .priceTableRow:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(117, 126, 134, 0.5);
  }

  .priceTableRow td {
    padding: 0;
    border: none;
  }

  td.priceTableRow__data {
    grid-column: 3 / span 1;
    padding: 5px 0;
    padding-left: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  td.priceTableRow__data_hidden {
    display: none;
  }

  .priceTableRow__action {
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
    text-align: left;
    align-self: end;
    margin-bottom: 5px;
  }

  .priceTableRow__button {
    min-height: 43px;
  }

  .priceTableRow__label {
    font-weight: 400;
  }
}
