.textBlock {
  font-size: var(--sFontBig);
}

.textBlock__table {
  overflow: auto;
  margin-block-start: 3em;
  margin-block-end: 3em;
}

.textBlock__table table {
  min-width: 100%;
  border-collapse: collapse;
}

.textBlock__table th {
  padding: 24px 12px;
  border-bottom: 1px solid var(--cText);
  text-align: left;
}

.textBlock__table td {
  padding: 10px 12px;
}

.textBlock__table thead + tbody tr:first-child td {
  padding-top: 20px;
}

.textBlock__table tbody tr:nth-child(2n) td {
  background-color: #ECEDF2;
}

.textBlock > *:first-child {
  margin-top: 0;
}

.textBlock p {
  margin-block-start: 2em;
  margin-block-end: 2em;
}

.textBlock h1,
.textBlock h2,
.textBlock h3,
.textBlock h4,
.textBlock h5,
.textBlock h6 {
  margin-block-start: 1.5em;
  margin-block-end: 1em;
}

.textBlock h2 {
  font-size: var(--sFontH3);
}

.textBlock ul {
  margin-block-start: 2em;
  margin-block-end: 2em;
  padding-inline-start: 45px;
  list-style: none;
}

.textBlock ul li {
  margin-block-start: 1.5em;
  margin-block-end: 1.5em;
  position: relative;
}

.textBlock ul li::before {
  content: "";
  position: absolute;
  left: -36px;
  top: 0.75em;
  width: 7px;
  height: 7px;
  margin-top: -3px;
  background-color: var(--cTheme);
  border-radius: 50%;
}

.textBlock dl {
  margin-block-start: 2em;
  margin-block-end: 2em;
  max-width: 550px;
}

.textBlock dl div > * {
  display: inline;
  padding: 0;
  margin: 0;
}

.textBlock dl dt {
  font-weight: bold;
}
